import './App.css';
import Hero from './components/Hero';
import Reason from './components/Reasons/Reason';
import Footer from './components/footer/Footer';
import Join from './components/join/Join';
import Plan from './components/plans/plan';
import Testimonial from './components/testimonials/Testimonial';
import Program from './programs/Program';
function App() {
  return (
    <div className="App">
          <Hero/>
          <Program/>
          <Reason/>
          <Plan/>
          <Testimonial/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
