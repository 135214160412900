import React from "react";
import './Hero.css';
import Header from "../header/Header";
import hero_image from "../assets/hero_image.png";
import hero_image_back from "../assets/hero_image_back.png";
import Heart from "../assets/heart.png";
import Calories from "../assets/calories.png";
import {motion} from 'framer-motion'
import  NumberCounter from "number-counter";
const Hero =() => {
  const transition = {type:'string',duration: 5};
  const mobile = window.innerWidth<= 768 ? 'true' : 'false';
    return (
      <div className="hero" id="home">
        <div className="blur hero-blur"></div>
        <div className="left-h">
          <Header/>
          {/* ad section  */}
          <div className="the-best-ad">
            <motion.div
            initial={{left: mobile?'160px': '238px'}}
            whileInView={{left:'8px'}}
            transition = {{...transition,type:'tween'}}
            ></motion.div>
            <span>the best place for fitness</span>
          </div>

          {/* hero section  */}
          <div className="hero-text">
            <div><span className='stroke-text'>
              shape </span><span>your</span>
            </div>
            <div><span>
                ideal body</span>
            </div>
            <div>
            <span>In here we will help you to shape and build your
               ideal body and live up your life to the fullest </span>
           </div>
          </div>
          <div className="figures">
            <div>
              <span>
                <NumberCounter start={100} end={280} delay='5' preFix='+'></NumberCounter>
              </span>
              <span>expert coaches</span></div>
            <div>
              <span><NumberCounter start={100} end={480} delay='3.5' preFix='+'></NumberCounter>
              </span>
              <span>new members</span>
            </div>
            <div>
              <span><NumberCounter start={100} end={600} delay='3' preFix='+'></NumberCounter>
              </span>
              <span>fitness schedule</span>
            </div>
          </div>
          <div className="hero-button">
            <button className="btn">get started</button>
            <button className="btn">learn more</button>
          </div>
        </div>
        
        <div className="right-h">
          <button className="btn">join now</button>
          <motion.div className="heart-rate"
          initial={{left:'0'}}
          whileInView={{left:'1rem'}}
          transition = {transition}>
            <img src={Heart} alt="" />
            <span>heart rate</span>
            <span>116 bpm</span>
          </motion.div>

          <img src={hero_image} alt="" className="hero_image"/>
          <motion.img 
          initial={{left:'10rem'}}
          whileInView={{left:'2rem'}}
          transition = {transition}
          src={hero_image_back} alt="" className="hero_image_back"/>

          {/* calories */}
          <motion.div className="calories"
          initial={{left:'0'}}
          whileInView={{left:'1rem'}}
          transition = {{...transition,type:'tween'}}>
            <img src={Calories} alt="" />
            <div>
              <span>calories burned</span>
              <span>220kcal</span >
            </div>
          </motion.div>
        </div>
      </div>
    );
  }
  
  export default Hero;