import React from "react";
import './Program.css';
import { programsData } from "../data/programsData";
import rightArrow from "../assets/rightArrow.png";
const Program = () =>{
    return (
       <div className="program" id="programs">
        {/* header  */}
        <div className="program-header">
            <span className="stroke-text">explore our</span>
            <span>program</span>
            <span className="stroke-text">to shape you</span>
        </div>
        <div className="program-categories">
          {programsData.map((program)=>(
            <div className="category">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="join-now"><span>join now</span> <img src={rightArrow} alt=""/></div>
            </div>
          ))}  
        </div>
       </div>
    )
}
export default Program;