import React from "react";
import './Reason.css';
import adidas from "../../assets/adidas.png";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import nb from "../../assets/nb.png";
import tick from "../../assets/tick.png";
import nike from "../../assets/nike.png";
const Reason = () => {
   return (
    <div className="reason" id="reasons">
        <div className="left-r">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
            <img src={image3} alt="" />
            <img src={image4} alt="" />
        </div>
        <div className="right-r">
            <div>
                <span className="stroke-text">why </span>
                <span>choose us</span>
            </div>
            <div className="details-r">
                <div>
                    <img src={tick} alt="" />
                    <span>over 140+ experts</span>
                </div>
                <div>
                    <img src={tick} alt=""/>
                    <span>train smarter</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>free program for members</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>reliable partners</span>
                </div>
            </div>
            <span className="partner">our partners</span>
            <div className="partner-icon">
                <img src={nb} alt="" />
                <img src={adidas} alt="" />
                <img src={nike} alt="" />
            </div>
        </div>
    </div>
   )
};
export default Reason;