import React from 'react';
import './Header.css';
import Logo from '../assets/logo.png';
import bars from '../assets/bars.png';
import { useState } from 'react';
import {Link} from 'react-scroll';
const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);
    return (
      <div className="header">
        <img src={Logo} alt="" className='logo' />
        {menuOpened === false && mobile === true ? ( <div 
        style={{background:'var(--appColor',
        padding:'.5rem',
        borderRadius:'.5rem',
        top:'1rem'}}
        onClick = {() => setMenuOpened(true)}
        >
        <img src={bars} alt="" style={{width:'1.5rem',height:'1.5rem',top:'1rem'}} /> </div>):(
        <ul className="header-menu">
        <li>
        <Link
          to='home'
          activeClass='active'
          span={true}
          smooth={true}
          onClick={()=> setMenuOpened(false)}
          >home</Link>
          </li>
        <li>
        <Link
          to='programs'
          span={true}
          smooth={true}
          onClick={()=> setMenuOpened(false)}>programs</Link>
          </li>
        <li>
        <Link
          to='reasons'
          span={true}
          smooth={true}
          onClick={()=> setMenuOpened(false)}
          >why us</Link>
          </li>
        <li>
        <Link
          to='plans'
          span={true}
          smooth={true}
          onClick={()=> setMenuOpened(false)}
          >plans</Link>
          </li>
        <li>
          <Link
          to='testimonial'
          span={true}
          smooth={true}
          onClick={()=> setMenuOpened(false)}
          >
          testimonials</Link>
          </li>
      </ul>) }
        
      </div>
    );
  }
  
  export default Header;