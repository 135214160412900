import React from 'react';
import './Join.css';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Join = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_1pvmhpf', 'template_f1oh8hg', form.current, '1L9ejYF4VSX_l7b9Q')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    return(
       <div className="join" id="join">
        <div className="left-j">
            <hr />
            <div>
                <span className='stroke-text'>ready to </span>
                <span>level up </span>
            </div>
            <div>
                <span>your body </span>
                <span className='stroke-text'>with us </span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className="form-container" onSubmit={sendEmail}>
                <input type="email" name='user_email' placeholder='Enter your email' />
                <button className='btn btn-j'>join now</button>
            </form>
        </div>
       </div> 
    )
}
export default Join;